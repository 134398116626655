<template>
    <div class="hide-scrollbar relative h-full" style="width:100%; background-color: #F7F5F9; overflow-y: scroll">
        <FormModal />
		<a-modal
			v-model="exportModal.visible"
			width="500px"
			:footer="null"
			@cancel="closeExportModal"
			:centered="true"
		>
			<BHLoading :show="loading" />
			<div class="mb-2">
				<h5 class="mb-4" v-text="'Export to Existing Project'" />

				<p class="mb-2" style="color: #40454c; font-size: 14px">
					Select a Project<span style="color: var(--danger)"> *</span>
				</p>
				<a-select
					size="large"
					v-model="exportModal.instance"
					class="w-full"
					placeholder="Select a Project"
					show-search :filter-option="filterOption"
				>
					<a-select-option v-for="(inst,instI) in instances" :value="inst.id" :key="inst+instI">{{inst.name}}</a-select-option>
				</a-select>
			</div>
			<a-alert v-if="exportModal.template && exportModal.template.type === 'worksheet'" type="info" show-icon class="mt-3">
				<template slot="message">
					Worksheet forms can be exported for projects of the same type. Lowrise worksheet forms should be exported only for lowrise projects, and highrise worksheet forms should be used exclusively for highrise projects.
				</template>
			</a-alert>
			<div class="dF jE mt-3">
				<a-button class="cancel-button" @click="closeExportModal">CANCEL</a-button>
				<a-button class="ml-3" type="primary" @click="confirmExport()">
					EXPORT
				</a-button>
			</div>
		</a-modal>

        <!-- VIEW FORM TEMPLATES -->
        <div v-if="selectedRowKeys.length" class="w-full dF jE" style="padding-bottom: 15px; padding-right: 20px;">
            <MoreActions shape="button" buttonText="Bulk Actions" :items="bulkActions" @action="bulkActioned" />
        </div>

        <div class="contacts-page container" style="background-color: white; padding-right: 0; padding-left: 0; margin-bottom: 15px; margin-right: 15px; margin-left: 15px;" :style="[forms.length? {'':''}:{'height':'calc(100% - 70px)'}]">

            <div class="relative" v-if="forms.length">
                <BHLoading :show="loadingPage" />
                <a-table :rowKey="(e) => e.id" class="tableStyle" :row-selection="rowSelection" :columns="columns" :data-source="specificForms" :pagination="false" @change="tableChange">
                    <div slot="formname" slot-scope="name">
                        <div @click="editForm(name)" style="font-weight: bold; cursor: pointer;">{{name.name}}</div>
                    </div>
					<div slot="group" slot-scope="group">
                        <div  v-if="group.group">{{ group.group && group.group.name ? group.group.name : 'N/A' }}</div>
                        <span v-else>N/A</span>
                    </div>
                    <div slot="lastentry" slot-scope="update">
                        <div  v-if="update.lastRegistered" style="color: #9EA0A5;">{{formatDate(update.lastRegistered)}}</div>
                        <span v-else>N/A</span>
                    </div>
                    <div slot="datecreated" slot-scope="create">
                        <div style="color: #9EA0A5;">{{formatDate(create.createdAt)}}</div>
                    </div>
                    <div slot="actions" slot-scope="form">
                        <a-popover trigger="click" overlayClassName="popoverStyle">
                            <div slot="content">
                                <div @click="previewForm(form)" class="popoverContent"><a-icon type="eye" class="mr-2"/>Preview</div>
                                <div @click="editForm(form)" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 mr-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>Edit</div>
                                <div @click="copyForm(form)" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy mr-2"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>Duplicate Form</div>
                                <!-- <div v-clipboard:copy="`https://forms.bildhive.app/${form.id}`" @click="() => $message.success('Link Copied')" v-if="form.type == 'walk-in'" class="popoverContent"><a-icon class="mr-2" type="link" />Copy URL</div> -->
                                <div v-if="form.type == 'survey'" @click="viewReport(form)" class="popoverContent"><a-icon type="bar-chart" class="mr-2" />View Report</div>
                                <div @click="downloadSubmissions(form)" class="popoverContent"> <a-icon type="download" class="mr-2" /> Download CSV</div>
								<div @click="exportForm(form)" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload mr-2"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>Export to Project</div>
                                <div @click="deleteForm(form)" class="popoverContent"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2 mr-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>Delete</div>
                            </div>
                            <div class="more-option-icon" style="cursor: pointer;">
                                <a-icon style="line-height: 40px;" type="more" />
                            </div>
                        </a-popover>
                    </div>
                </a-table>
            </div>

            <div v-else class="w-full h-full dF aC jC">
                <EmptyApp :text="'You don\'t have any forms/surveys'" :img="require('@/assets/no-forms.svg')" />
            </div>
        </div>

    </div>
</template>

<script>
    import {formatDate} from 'bh-mod'
    import FormModal from '@/components/forms/FormModal'
    import BHLoading from 'bh-mod/components/common/Loading'
    import MoreActions from 'bh-mod/components/common/MoreActions'
    import EmptyApp from 'bh-mod/components/common/EmptyApp'

    export default {
        components:{
            BHLoading,FormModal,MoreActions,EmptyApp
		},
        data() {
            return {
                templates:[],
                bulkActions:[
                    {
                        label:'Delete Selected Forms/Surveys',
                        value:'delete',
                        key:'delete'
                    }
                ],
				loading:false,
				exportModal:{
					visible:false,
					template:{},
					instance:''
				},
                mailOpen:'',
                filter:'all',
                baseImage:'',
                loadModal:false,
                taskOpen:'',
                appointmentOpen:'',
                searchMode:false,
                selectedRowKeys: [],
                specificForms: [],

                columns: [
                    { title: 'Form Name', scopedSlots: { customRender: 'formname' }, key: 'formname', sorter:{compare: (a, b) => a.name.localeCompare(b.name)} },
                    { title: 'Group', scopedSlots: { customRender: 'group' }, key: 'group', sorter:this.sortGroup },
					{ title: 'Entries', dataIndex: 'entries', key: 'entries'},
                    { title: 'Last Entry Added', scopedSlots: { customRender: 'lastentry' }, key: 'lastentry' },
                    { title: 'Date Created', scopedSlots: { customRender: 'datecreated' }, key: 'datecreated', sorter:{compare: (a, b) => a.createdAt.localeCompare(b.createdAt)} },
                    { title: 'Actions', scopedSlots: { customRender: 'actions' }, key: 'actions' },
                ]
            }
        },
        watch:{
            'form.options'(val){
                console.log('FORM CHANGED', val)
            },
            search(val){
                this.$store.commit('SHOW_EDIT_FORM', val)
                this.$store.commit('OPEN_EDIT_FORM_PAGE')

            },
            currentForm(val){
                this.form = val
            },
            searchTimeStamp(val){
                this.$store.dispatch('DO_SEARCH')
            },
            currentPage(){
                this.$store.dispatch('DO_SEARCH')
            },
            forms(val){
                let forms = []
                if (this.formType == 'All Form/Survey Templates'){
                    forms = val
                } else if (this.formType == 'Form Templates'){
                    val.forEach( x => {
                        if (x.type == 'form'){
                            forms.push(x)
                        }
                    })
                } else if (this.formType == 'Worksheet Templates'){
                    val.forEach( x => {
                        if (x.type == 'worksheet'){
                            forms.push(x)
                        }
                    })
                } else {
                    val.forEach( x => {
                        if (x.type == 'survey'){
                            forms.push(x)
                        }
                    })
                }

                console.log('FORMS BEFORE SETTING SPECIFIC FORMS', forms)
                this.specificForms = forms
            },
            $route (to, from){
                console.log('ROUTE WATCH RAN')
                this.selectedRowKeys = []
                this.updateForms();
            },
            rightBar:{
                handler(val){
                    if (val){
                        console.log('RIGHT BAR SELECTED WATCH RAN', val)
                        setTimeout(() => {
                            var elem = document.querySelector(".field-selected");
							if(elem != null){
								elem.scrollIntoView();
							}
                        }, 100)
                    }
                },
                deep:true
            }
        },
        computed:{
            search(){
                return this.$store.state.search.form
            },
            formType(){
                return this.$store.state.formType
            },
            forms(){
                return Object.values(this.$store.state.forms.allForms)
            },
            rowSelection() {
                const { selectedRowKeys } = this;
                return {
                    selectedRowKeys,
                    onChange: this.onSelectChange,
                    hideDefaultSelections: false
                };
            },
            searchTimeStamp(){
                return this.$store.state.forms.searchTimeStamp
            },
            currentPage:{
                get(){
                    return this.$store.state.forms.currentPage
                },
                set(val){
                    this.$store.commit('SET_CONTACTS_PAGE', val)
                }
            },
            tags(){
                return Object.values(this.$store.state.forms.tags)
            },
            searchOBJ(){
                return this.$store.state.forms.searchOBJ
            },
            resultLength(){
                return this.$store.state.forms.resultLength
            },
            newStoreTime(){
                return this.$store.state.forms.newStoreTime
            },
			instances() {
				return this.$store.state.instances.filter(x => {
					if (x.id != this.instance.id) {
						if (this.exportModal && this.exportModal.template && this.exportModal.template.type === "worksheet") {
							return x.productType === this.instance.productType
						}
						return true
					}
					return false
				}).sort((a, b) => {
					let nameA = a.name.toUpperCase()
					let nameB = b.name.toUpperCase()
					if (nameA < nameB) return -1
					if (nameA > nameB) return 1
					return 0
				})
			},
            instance(){
                return this.$store.state.instance
            },
            pageSize(){
                return this.$store.state.forms.pageSize
            },
            query(){
                return this.$store.getters.query
            },
            loadingPage:{
                get(){
                    return this.$store.state.forms.loadingPage
                },
                set(val){
                    this.$store.commit('LOAD_PAGE',val)
                }
            },
            dialog(){
                return this.$store.state.forms.formDrawer
            }
        },
        methods:{
			sortGroup(a,b){
				let nameA = a.group && a.group.name ? a.group.name.toUpperCase() : 'N/A'
				let nameB = b.group && b.group.name ? b.group.name.toUpperCase() : 'N/A'
				if (nameA < nameB) return -1
				if (nameA > nameB) return 1
				return 0
			},
			exportForm(form){
				console.log('EXPORTING FORM...', form)
                this.exportModal = {
					visible: true,
					template: form,
					instance:''
				}
			},
			closeExportModal(){
				this.exportModal = {
					visible:false,
					template:{},
					instance:''
				}
			},

			filterOption(input, option) {
				return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
			},

			async confirmExport(){
                if (this.exportModal.instance == ''){
					return this.$message.error('Please select an instance')
				}
                this.loading = true
				let sendObj = JSON.parse(JSON.stringify(this.exportModal.template))
                sendObj.name = sendObj.name + ' (copy)',
				sendObj.tags = []
				sendObj.users = []
				sendObj.options.fromEmail = ""
				sendObj.instance = this.exportModal.instance
				sendObj.lastRegistered = 0
				delete sendObj.entries
                this.$api.post(`/forms/${this.exportModal.instance}`, sendObj)
					.then( ({data}) => {
                    	this.$message.success(`Exported form: ${this.exportModal.template.name}`)
						this.loading = false;
						this.closeExportModal()
                    }).catch(err => {
                        this.$message.error('Error while duplicating form')
						this.loading = false;
                    }).finally( () => {
                        this.loading = false;
						this.closeExportModal()
                    })
            },
            tableChange(pagination, filters, sorter, extra){
                console.log('params', pagination, filters, sorter, extra)
                let forms = JSON.parse(JSON.stringify(this.forms))
                if (sorter.columnKey == 'formname'){
                    if (sorter.order == 'ascend'){
                        this.specificForms = forms.sort((a, b) => a.name.localeCompare(b.name))
                    } else if (sorter.order == 'descend'){
                        this.specificForms = forms.sort((a, b) => b.name.localeCompare(a.name))
                    } else {
                        this.specificForms = forms
                    }
                } else if (sorter.columnKey == 'datecreated'){
                    if (sorter.order == 'ascend'){
                        this.specificForms = forms.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
                    } else if (sorter.order == 'descend'){
                        this.specificForms = forms.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                    } else {
                        this.specificForms = forms
                    }
                }
            },
            async bulkActioned({key,keyPath,val}){
                let self = this
                console.log('KEYYYYY',key)

                if (key == 'delete') {
                    this.$confirm({
                        title: "Delete",
                        content: h => <div>Do you want to delete these forms/surveys?</div>,
                        okText: 'Delete',
                        okType: 'danger',
                        cancelText: 'Cancel',
                        centered: true,
                        onOk() {
                            for (var i=0; i<self.selectedRowKeys.length; i++) {
                                let id = self.selectedRowKeys[i]
                                console.log('ID', id)
                                self.confirmDeleteForm(self.$store.state.forms.allForms[id])
                            }
                            setTimeout(() => {
                                self.selectedRowKeys = []
                            }, 1000);
                        },
                        onCancel() {
                            console.log('Cancel')
                        }
                    })
                }
            },
            getCurrentStyle(current, today) {
                const style = {};
                if (current.date() === 1) {
                    style.border = '1px solid #1890ff';
                    style.borderRadius = '50%';
                }
                return style;
            },
            previewForm(data){
                this.$router.push(`preview/${data.id}`)
            },
            editForm(data){
                this.$router.push(`/edit/${data.id}`)
            },
            copyForm(data){
                console.log('COPY FORM', data)
                let sendObj = JSON.parse(JSON.stringify(data))
                sendObj.name = sendObj.name + ' (copy)',
				sendObj.entries = 0
				delete sendObj.group
                this.$api.post(`/forms/${this.instance.id}`, sendObj).then( ({data}) => {
                    console.log('COPY DATA COMING BACK', data)
					this.$message.success('Form duplicated successfully')
                    this.onClose2({type:'add',data})
                    // this.$store.commit('FORM_TYPE', {value:'All Form/Survey Templates'})
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            },
            viewReport(form) {
				window.open(`https://reports.bildhive.${this.$tld}/survey/${form.id}`, "_blank")
			},
            downloadSubmissions(data){
                fetch(`https://hook.bildhive.${this.$tld}/forms/${data.instance}/${data.id}/csv`, {
                    headers: {
                        'Content-Type': 'text/csv'
                    },
                    responseType: 'blob'
                    }).then(response => response.blob())
                    .then(blob => {
                        console.log('BLOBBBB', blob)
                        var a = window.document.createElement("a");
                        a.href = window.URL.createObjectURL(blob, {
                            type: "text/csv"
                        });
                        a.download = `${data.name}_${Date.now()}.csv`
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    });
            },
            deleteForm(data){
                if (this.$p < 40) return this.$message.error('You do not have permission to delete this form')
                console.log('DELETE FORM', data)
                let self = this
                this.$confirm({
                    title: 'Delete Form?',
                    content: h => <div>Do you want to delete this form?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk(){
                        self.confirmDeleteForm(data)
                        setTimeout(() => {
                            self.selectedRowKeys = []
                        }, 1000);
                    },
                    onCancel(){
                        console.log('Cancel')
                    }
                })
            },
            confirmDeleteForm(data){
                this.$api.delete(`/forms/${this.instance.id}/${data.id}`).then( ({data}) => {
                    console.log('DATA', data)
                    for (var i = 0; i<this.specificForms.length; i++){
                        if (this.specificForms[i].id == data.id){
                            this.specificForms.splice(i,1)
                        }
                    }
                    this.onClose2({type:'delete',data})
                    this.$store.commit('UPDATE_FORMS', data)
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            },
            formatDate,
            onSelectChange(selectedRowKeys) {
                console.log('selectedRowKeys changed: ', selectedRowKeys);
                this.selectedRowKeys = selectedRowKeys;
            },
            removeSearch({srch,searchKey}){
                console.log('REMOVING', srch)
                console.log('REMOVING', searchKey)
                this.$store.commit('REMOVE_SEARCH_PARAM',{srch,searchKey})
            },
            onClose(){
                this.fieldSelect = false
            },
            onClose2({type='',data={}}){
                this.$store.dispatch('CLOSE_FORM_DRAWER',{type,data})
            },
            hasErrors(){
                if (!this.form.name.trim()) return this.$message.error('Form needs a name'),true
            },


            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },

			updateForms() {
				let forms = []
				if (this.$route.path == '/') {
					this.$store.commit('PREVIOUS_ROUTE', '/')
					this.$store.commit('FORM_TYPE', { value: 'All Form/Survey Templates' })
					forms = this.forms
				} else if (this.$route.path == '/forms') {
					this.$store.commit('PREVIOUS_ROUTE', '/forms')
					this.$store.commit('FORM_TYPE', { value: 'Form Templates' })
					this.forms.forEach(x => {
						if (x.type == 'form') {
							forms.push(x)
						}
					})
				} else if (this.$route.path == '/surveys') {
					this.$store.commit('PREVIOUS_ROUTE', '/surveys')
					this.$store.commit('FORM_TYPE', { value: 'Survey Templates' })
					this.forms.forEach(x => {
						if (x.type == 'survey') {
							forms.push(x)
						}
					})
				} else if (this.$route.path == '/walk-in') {
					this.$store.commit('PREVIOUS_ROUTE', '/walk-in')
					this.$store.commit('FORM_TYPE', { value: 'Walk-In Templates' })
					this.forms.forEach(x => {
						if (x.type == 'walk-in') {
							forms.push(x)
						}
					})
				} else if (this.$route.path == '/worksheets') {
					this.$store.commit('PREVIOUS_ROUTE', '/worksheets')
					this.$store.commit('FORM_TYPE', { value: 'Worksheet Templates' })
					this.forms.forEach(x => {
						if (x.type == 'worksheet') {
							forms.push(x)
						}
					})
				}
				this.specificForms = forms
			}
        },
        mounted(){
			this.$store.dispatch('FETCH_REALTORS')
            let options = []
            if (this.childInstances && this.childInstances.length) {
                this.childInstances.forEach(instance => {
                    let obj = {
                        label:instance.name,
                        id:instance.id,
                        active:false,
                        option:instance.name
                    }
                    options.push(obj)
                })
                let index = this.customFieldChoices.findIndex(x => x.type == 'child')
                if (index != -1){
                    this.customFieldChoices[index].options = options
                }
            }
			this.$api.get('/instances').then(({data}) => {
				data = data.filter(x => x.role && x.role.name === 'bh_admin').map(x => {
					return {
						id: x.instance.id,
						name: x.instance.name,
						productType: x.instance.productType
					}
				})
				this.$store.commit('SET_INSTANCES', data)
			}).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        created() {
            this.$store.commit('PAGE_BUTTONS', [
                {
                    label:'ADD FORM',
                    ant:'plus',
                    callback: () => this.$store.commit('SHOW_ADD_FORM')
                }
            ])

            this.updateForms();
        }

    }
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>

    @media screen and (min-width: 1250px){
        .responsiveFlex {
            display: flex;
            gap: 1.25em;
            padding-bottom:8rem;
        }
        .leftSide {
            width: 75%;
        }
        .rightSide {
            width: 25%;
        }
    }
    @media screen and (max-width: 1250px){
        .responsiveFlex {
            display: block;
        }
        .leftSide {
            width: 100%;
        }
        .rightSide {
            width: 100%;
        }
    }
    .edit-delete {
		cursor: pointer;
		color: rgba(0, 0, 0, 0.65);
		transition:color .1s ease-in;
	}
	.edit-delete:hover {
		color: orange;
		transition:color .1s ease-in;
	}
    .radio-style {
        fill: #3f3356;
    }
    .addField{
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background-color: var(--orange);
        line-height: 44px;
        text-align: center;
        color: white;
        font-size: 20px;
        margin: 24px auto 0 auto;
        cursor: pointer;
    }
    .fieldOptions {
        width: 100%;
        height: 45px;
        line-height: 45px;
    }
    .fieldCard {
        box-shadow: 0px 1px 3px rgba(63, 63, 68, 0.15);
        &:hover{
            cursor: pointer;
            border-color: var(--orange);
        }
    }
    .contacts-page{
        @media screen and (min-width:567px){
            max-width: calc(100% - 35px);
        }
    }
    .aB{
        align-items: baseline;
    }
    .more-option-icon{
        width: 35px;
        height: 35px;
        border-radius: 35px;
        text-align: center;
        background-color: transparent;
    }
    .more-option-icon:hover{
		color: var(--orange) !important;
        background-color: var(--light-gray);
	}

    .popoverContent{
        height: 35px;
        width: 160px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
    .responsiveFlex {
        padding-bottom:8rem;
    }

</style>

<style lang="scss">
    .remove-leftmargin{
        margin-left:0 !important;
    }
    .ps__thumb-x{
        display: none;
    }
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
    .tableStyle .ant-table-thead > tr > th {
        background-color: white;
    }
    .rightForm {
        .lead .ant-card-head-wrapper{
            width: 100px;
        }
        .feedLead .ant-card-head-wrapper{
            width: 175px;
        }
        .autoresponder .ant-card-head-wrapper{
            width: 130px;
        }
        .tags .ant-card-head-wrapper{
            width: 195px;
        }
        .notification .ant-card-head-wrapper{
            width: 188px;
        }
        .thankyou .ant-card-head-wrapper{
            width:160px;
        }
    }

</style>
